/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7caaa468-e020-4dbd-8a43-bd87a680c6b6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RImSw6bKV",
    "aws_user_pools_web_client_id": "5dcaq5ol1m5u97g2k09p3pon9e",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ytz5nzyzg5cpbdh5xeb34apuqi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-232t3ixt2ng7fgd7ojlu4ck2ou",
    "aws_user_files_s3_bucket": "boditelweba18e005727b3442397d01d5ed618e42810225-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
